.video-container {
  overflow: hidden;
  position: relative;
  width: 100%;
  margin-top: 10px;
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: "";
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-tni-polri {
  border-radius: 10px;
}
.title-apa-kata-mereka {
    color: white;
    text-align: center;
    font-size: 28px;
}
.container-video-tni-polri {
    margin-top: 30px;
}
.garis-apa-kata-mereka {
    width: 20%;
    border: 1px solid white;
}
.parent-title-apa-kata-mereka {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

/* RESPONSIVE */
@media only screen and (min-width: 320px) and (max-width: 896px) {
    .title-apa-kata-mereka {
        font-size: 18px;
    }
    .parent-title-apa-kata-mereka {
        gap: 10px;
    }
  }