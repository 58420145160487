.parent-liputan {
    background-image: url("/src/assets/mt_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 30px;
    margin-top: 40px;
    padding: 20px;
    border-radius: 10px;
}
.box-liputan {
    display: flex;
    justify-content: center;
    align-items: normal;
    flex-wrap: wrap;
    gap: 10px;
}
.img-liputan {
    width: 20%;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.img-liputan-nobg {
    width: 20%;
    border-radius: 2px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    background-color: white;
    padding: 0 10px;
}
.title-telah-diliput {
    text-align: center;
    color: white;
}

/* RESPONSIVE */
@media only screen and (min-width: 320px) and (max-width: 896px) {
    .title-telah-diliput {
        font-size: 20px;
    }
    .parent-liputan {
        padding: 10px;
    }
    .img-liputan, .img-liputan-nobg {
        width: 48%;
    }
  }