.box-materi-akpol {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    width: 100%;
}
.title-materi-tni-polri {
    display: flex;
    justify-content: left;
    align-items: center;
    gap: 10px;
    font-family: "Montserrat", sans-serif;
    font-weight: 800;
    font-size: 20px;
    background-color: #054599;
    border: 1px solid transparent;
    color: white;
    padding: 6px;
    border-radius: 50px 0 0 50px;
    padding-right: 10px;
    width: 100%;
}
.container-materi-tni {
    background-image: url("/src/assets/mt_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    gap: 20px;
    padding: 25px;
    margin-top: 30px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.parent-list-materi-tni-polri {
    margin-top: 10px;
    margin-left: 25px;
}
.list-materi-tni-polri {
    line-height: 30px;
    font-weight: 500;
}
.icon-materi-tni-polri {
    color: #ffffff;
    background-color: rgb(231, 159, 2);
    padding: 1px;
    border-radius: 100px;
}
.title-list-materi-tni-polri {
    margin-top: 15px;
    background-color: rgb(238, 238, 238);
    padding: 5px;
}
.img-materi-tni-polri {
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 100px;
}

/* RESPONSIVE */
@media only screen and (min-width: 320px) and (max-width: 896px) {
    .container-materi-tni {
        display: grid;
        padding: 10px;
    }
    .parent-list-materi-tni-polri {
        margin-left: 10px;
    }
    .title-materi-tni-polri {
        text-align: left;
        font-size: 18px;
    }
    .title-list-materi-tni-polri {
        font-weight: 400;
        font-size: 13px;
    }
    .list-materi-tni-polri {
        font-weight: 400;
        font-size: 12px;
    }
}