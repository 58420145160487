.container-paket-iup {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 15px;
  padding: 20px;
}
.card-paket-iup {
  background-image: url("/src/assets/wave_program_tni.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  padding-top: 25px;
  transition: 0.5s;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 48%;
}

/* RESPONSIVE */
@media only screen and (max-width: 500px) {
  .container-paket-iup {
    display: grid;
    place-items: center;
    padding: 10px;
  }
  .card-paket-iup {
    width: 100%;
  }
  .subtext-paket-iupkki {
    font-weight: 400;
    font-size: 15px;
  }
  .note-text-iupkki {
    font-size: 12px;
  }
  .bold-text-paket {
    font-weight: 500;
    font-size: 15px;
  }
}
