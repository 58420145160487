.box-kegiatan-belajar-snbt {
    background-image: url("/src/assets/mt_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 10px;
    margin-top: 30px;
    padding: 10px;
    border-radius: 10px;
}
.title-kegiatan-belajar-snbt {
    color: white;
    text-align: center;
}
.parent-kegiatan-belajar-snbt {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}
.child-kegiatan-belajar-snbt {
    width: 30%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
}
.text-belajar-online {
    text-align: center;
    color: white;
}
.parent-text-belajar-online {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}
.garis-kegiatan-belajar {
    width: 20%;
    border: 1px solid white;
}

/* RESPONSIVE */
@media only screen and (min-width: 320px) and (max-width: 896px) {
    .child-kegiatan-belajar-snbt {
        width: 100%;
    }
    .parent-kegiatan-belajar-snbt {
        justify-content: center;
        gap: 5px;
    }
    .title-kegiatan-belajar-snbt {
        font-size: 25px;
    }
    .text-belajar-online {
        font-size: 15px;
        font-weight: 400;
    }
    .garis-kegiatan-belajar {
        border: 0.9px solid white;
    }
    .box-kegiatan-belajar-snbt {
        gap: 10px;
    }
}