.box-success-story-lpdp {
    background-image: url("/src/assets/mt_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 100px;
    margin-top: 30px;
    padding: 10px;
    border-radius: 10px;
}
.title-success-story-lpdp {
    color: white;
    text-align: center;
}
.parent-success-story-lpdp {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}
.child-success-story-lpdp {
    width: 32%;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 5px;
}

/* RESPONSIVE */
@media only screen and (min-width: 320px) and (max-width: 896px) {
    .child-success-story-lpdp {
        width: 49%;
    }
    .parent-success-story-lpdp {
        justify-content: center;
        gap: 5px;
    }
    .title-success-story-lpdp {
        font-size: 20px;
    }
}