.container-popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5); /* Latar belakang transparan */
  z-index: 999;
  margin-top: 60px;
}

.popup-content {
  position: relative; /* Memberikan posisi relatif untuk tombol Close */
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-popup {
  width: 100%; /* Gambar lebih responsif dengan lebar 90% */
  max-width: 500px; /* Tetap membatasi lebar maksimal gambar */
  border-radius: 10px;
}

.close-btn {
  position: absolute;
  top: 10%; /* Jarak dari atas gambar */
  right: 36%; /* Jarak dari sisi kanan gambar */
  background-color: rgb(255, 255, 255);
  color: black;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  font-size: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
}
.parent-img-popup {
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-close {
  width: 20px;
  height: 20px;
}
/* RESPONSIVE */
@media only screen and (max-width: 768px) {
  .img-popup {
    width: 85%; /* Gambar sedikit lebih besar di perangkat lebih kecil */
  }

  .close-btn {
    top: 17%;
    right: 1%;
    padding: 10px;
    font-size: 18px; /* Ukuran font lebih kecil di perangkat kecil */
  }
}

@media only screen and (max-width: 480px) {
  .img-popup {
    width: 90%; /* Gambar akan semakin kecil di perangkat kecil */
  }

  .close-btn {
    top: 17%;
    right: 1%;
    font-size: 16px; /* Ukuran tombol lebih kecil */
  }
}
