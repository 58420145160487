/* MARQUEE */
.logo-school {
  margin-top: 20px;
}
.container-asal-sekolah {
  margin-top: 50px;
}
.parent-card-asalsekolah {
  display: flex;
}

.marquee {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 60vh;
  overflow-x: hidden;
  overflow: hidden;
}
.marquee2 {
  position: relative;
  width: 100vw;
  max-width: 100%;
  height: 200px;
  overflow-x: hidden;
}
.content {
  width: 90%;
}

.track {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee 20s linear infinite;
}

.track2 {
  position: absolute;
  white-space: nowrap;
  will-change: transform;
  animation: marquee2 20s linear infinite;
}
.desk-asal-sekolah {
  margin-top: 10px;
}
.title-asal-sekolah {
    color: white;
    text-align: center;
    font-size: 27px;
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
@keyframes marquee2 {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

/* RESPONSIVE */
@media only screen and (max-width: 500px) {
  .logo-school {
    width: 600%;
  }
  .marquee {
    height: 25vh;
  }
  .track {
    position: absolute;
    white-space: nowrap;
    will-change: transform;
    animation: marquee 3s linear infinite;
  }
  .title-asal-sekolah {
    font-size: 20px;
    color: white;
    text-align: center;
  }
}
