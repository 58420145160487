.icon-materi-lpdp {
}
.box-materi-akpol {
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 100%;
}
.title-materi-lpdp {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-size: 20px;
  background-color: #054599;
  border: 1px solid transparent;
  color: white;
  padding: 6px;
  border-radius: 50px 0 0 50px;
  padding-right: 10px;
  width: 100%;
}
.container-materi-tni {
  background-image: url("/src/assets/mt_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 25px;
  margin-top: 30px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.parent-list-materi-lpdp {
  display: flex;
  align-items: baseline;
  gap: 10px;
  margin-top: 10px;
  margin-left: 25px;
}
.list-materi-lpdp {
  line-height: 30px;
  font-weight: 500;
}
.icon-materi-lpdp {
  color: #ffffff;
  background-color: rgb(231, 159, 2);
  padding: 1px;
  border-radius: 100px;
}
.title-list-materi-lpdp {
    display: flex;
    align-items: flex-start;
    gap: 10px;
  margin-top: 15px;
  background-color: rgb(238, 238, 238);
  padding: 5px;
}
.img-materi-lpdp {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 100px;
}
.icon-list-materi-lpdp {
    font-size:8px;
}

/* RESPONSIVE */
@media only screen and (min-width: 320px) and (max-width: 896px) {
  .container-materi-tni {
    display: grid;
    padding: 10px;
  }
  .parent-list-materi-lpdp {
    margin-left: 10px;
  }
  .title-materi-lpdp {
    font-size: 14px;
    text-align: left;
    font-weight: 700;
  }
  .title-list-materi-lpdp {
    font-size: 13px;
    font-weight: 500;
  }
  .list-materi-lpdp {
    font-size: 13px;
    font-weight: 400;
  }
  .img-materi-lpdp {
    width: 18%;
  }
}
