.container-konsultan {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background-color: white;
  padding-top: 30px;
  padding-bottom: 30px;
}
.img-konsultan {
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

/* RESPONSIVE */
@media only screen and (min-width: 320px) and (max-width: 896px) {
  .img-konsultan {
    width: 100%;
    border-radius: 5px;
  }
  .container-konsultan {
    padding: 10px;
    border-radius: 5px;
  }
}
