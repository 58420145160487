.img-garansi-bumn {
  width: 50%;
  margin-top: 20px;
}
.parent-img-garansi-bumn {
  display: flex;
  justify-content: left;
}
.card-paket-bumn {
  background-image: url("/src/assets/wave_program_tni.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white;
  border-radius: 8px;
  padding: 15px;
  padding-top: 25px;
  transition: 0.5s;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 47%;
}
.container-paket-bumn {
  display: flex;
  justify-content: center;
    /* align-items: center; */
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 20px;
  background-color: #054599;
  padding: 20px;
  border-radius: 10px;
}

/* RESPONSIVE */
@media only screen and (max-width: 500px) {
    .container-paket-bumn {
      display: grid;
      place-items: center;
    }
    .card-paket-bumn {
        width: 100%;
    }
    .img-garansi-bumn {
        width: 55%;
    }
  }