.parent-promo-bumn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .child-promo-bumn {
    width: 100%;
  }
  