.img-garansi-lpdp {
    width: 100%;
    margin-top: 20px;
}
.harga-paket-lpdp {
    text-decoration: line-through;
    text-align: left;
    font-size: 25px;
    color: black;
    margin-top: 25px;
}

.harga-paket-lpdp-2 {
    text-align: left;
    font-size: 28px;
    color: #054599;
}
.nama-paket-lpdp {
    font-size: 30px;
}
.note-paket-belajar-lpdp {
    text-align: left;
    background-color: #054599;
    color: white;
    font-style: italic;
    margin-top: 20px;
    padding: 10px;
    border-radius: 10px 50px 50px 10px;
    font-size: 16px;
    font-weight: 600;
}

/* RESPONSIVE */
@media only screen and (max-width: 500px) {
    .nama-paket-lpdp {
        font-size: 18px;
    }
    .note-paket-belajar-lpdp {
        margin-top: 10px;
        padding: 5px;
        font-size: 14px;
        font-weight: 400;
    }
    .img-garansi-lpdp {
        margin-top: 10px;
    }
}