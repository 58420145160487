.container-penjelasan-toefl {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 15px;
    margin-top: 40px;
}
.penjelasan-toefl-1 {
    background: white;
    padding: 15px;
    border-radius: 10px;
    width: 49%;
    border-right: 5px solid #054599;
    border-bottom: 5px solid #054599;
}
.penjelasan-toefl {
    background: white;
    padding: 15px;
    border-radius: 10px;
    width: 100%;
    border-right: 5px solid #054599;
    border-bottom: 5px solid #054599;
}
.desk-penjelasan-toefl {
    font-size: 17px;
    font-weight: 500;
    margin-top: 10px;
}
.penjelasan-toefl {
    background: white;
    padding: 15px;
    border-radius: 10px;
    width: 100%;
    margin-top: 15px;
}
.desk-penjelasan-toefl {
    font-size: 17px;
    font-weight: 500;
    margin-top: 10px;
}

/* RESPONSIVE */
@media only screen and (min-width: 320px) and (max-width: 896px) {
    .penjelasan-toefl-1 {
        width: 100%;
    }
    .desk-penjelasan-toefl {
        font-size: 14px;
        font-weight: 400;
        margin-top: 5px;
    }
    .title-penjelasan-toefl, .title-penjelasan-toefl {
        font-size: 14px;
    }
}