/* NEW */
.box-flex-best-program {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  background-color: #054599;
  border-radius: 10px;
  padding-top: 8px;
  padding-bottom: 8px;
}

/* OLD */
.box-best-program-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  background-color: #054599;
}
.parent-best-program-2 {
  display: flex;
  gap: 20px;
}
.main-best-program-2 {
  background-color: #054599;
  border-radius: 10px;
  padding: 15px;
  padding-top: 0;
}
.best-program-img-2 {
  width: 15%;
  border-radius: 3px;
}
.model-tanya-program-2 {
  width: 25%;
}
.title-best-program-2 {
  width: 50%;
  font-weight: 800;
  color: white;
  margin-top: 30px;
  padding-top: 10px;

}
.hot-program-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  position: relative;
  top: 40px;
  margin-left: 1px;
  z-index: 999;
  font-size: 15px;
  margin-top: -30px;
  background-color: #f52f63;
  width: 170px;
  padding: 5px;
  color: white;
  border-radius: 5px 15px 5px 15px;
}
.main-box-2 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 50px;
  margin-top: 50px;
}

/* RESPONSIVE */
@media only screen and (max-width: 500px) {
  .title-best-program-2 {
    font-size: 10px;
    width: 100%;
  }
  .hot-program-2 {
    display: none;
  }
  .box-best-program-2 {
    gap: 5px;
  }
  .main-best-program-2 {
    padding: 10px;
    padding-top: 0;
  }
  .parent-best-program-2 {
    flex-wrap: wrap;
  }
  .best-program-img-2 {
    border-radius: 2px !important;
    width: 48%;
  }
  .box-flex-best-program {
    gap: 10px;
    padding-bottom: 0;
  }
}
@media only screen and (max-width: 768px) {
  .hot-program-2 {
    display: none;
  }
  .title-best-program-2 {
    font-size: 20px;
  }
  .box-best-program-2 {
    gap: 5px;
  }
  .best-program-img-2 {
    border-radius: 5px;
  }
}
@media only screen and (max-width: 820px) {
  .hot-program-2 {
    display: none;
  }
  .title-best-program-2 {
    font-size: 20px;
  }
  .box-best-program-2 {
    gap: 5px;
  }
  .best-program-img-2 {
    border-radius: 5px;
  }
}
@media only screen and (max-width: 1024px) {
  .hot-program-2 {
    display: none;
  }
  .title-best-program-2 {
    font-size: 20px;
  }
  .box-best-program-2 {
    gap: 5px;
  }
  .best-program-img-2 {
    border-radius: 5px;
  }
}
