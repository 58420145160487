.container-halaman-program {
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 5%;
}
.content-program {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #054599;
  padding: 20px;
  border-radius: 10px;
}

/* NEW */
.content-halaman-program-edm {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #054599;
  padding: 20px;
  border-radius: 10px;
}
.parent-program-edm {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.model-program-edm {
  width: 80%;
}
.content-halaman-program-edm {
  display: flex;
  justify-content: center;
  align-items: center;
}

.isi-content-program {
  display: flex;
  justify-content: center;
  align-items: center;
}
.model-halaman-program {
  width: 27%;
}
.title-halaman-program {
  font-size: 30px;
  font-weight: 800;
}
.child-paragraf-program {
  margin-top: 20px;
}
.teks-content {
  display: grid;
  gap: 20px;
}
.parent-promo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 40px;
}
.discount-promo {
  width: 100%;
  margin-top: 50px;
  text-align: center;
  border-radius: 6px;
}

/* RESPONSIVE */
@media only screen and (max-width: 500px) {
  .container-halaman-program {
    padding-left: 5%;
    padding-right: 5%;
  }
  .content-halaman-program-edm {
    display: grid;
    margin-top: 100px;
  }
  .teks-content {
    margin-top: 0;
  }
  .model-halaman-program {
    position: fixed;
    z-index: 9999;
    right: 25px;
    bottom: 50px;
    width: 30%;
    filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.212));
  }
  .title-halaman-program {
    text-align: left;
    width: 100%;
    font-size: 25px;
  }
  .model-program-edm {
    width: 100%;
  }
  .paragraf-program {
    font-size: 14px;
    font-weight: 3=400;
  }
}
@media only screen and (max-width: 768px) {
  .container-halaman-program {
    padding-left: 3%;
    padding-right: 3%;
  }
  .teks-content {
    margin-top: 0;
    text-align: justify;
  }
  .content-program {
    gap: 25px;
  }
  .model-program-edm {
    width: 100%;
  }
  .content-halaman-program-edm {
    gap: 10px;
  }
}
@media only screen and (max-width: 820px) {
  .container-halaman-program {
    padding-left: 3%;
    padding-right: 3%;
  }
  .teks-content {
    margin-top: 0;
    text-align: justify;
  }
  .content-program {
    gap: 25px;
  }
}
@media only screen and (max-width: 1024px) {
  .container-halaman-program {
    padding-left: 3%;
    padding-right: 3%;
  }
  .teks-content {
    margin-top: 0;
    text-align: left;
  }
  .content-program {
    gap: 25px;
  }
}
