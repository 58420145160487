.flip-container {
  perspective: 1000px;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 15px;
}
.super-title-sistem-belajar {
  color: white;
  text-align: center;
  font-weight: 700;
}

.flip-box {
  width: 100%;
  height: 200px;
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  cursor: pointer;
}

.flip-container .flip-box:hover {
  transform: rotateY(180deg);
}

.flip-box-front,
.flip-box-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flip-box-front {
  background-color: white;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border-radius: 10px;
  border-right: 5px solid #054599;
  border-bottom: 5px solid #054599;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.flip-box-back {
  background-color: #054599;
  background-image: url("../../../assets//mt_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  transform: rotateY(180deg);
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.icon-sistem-belajar {
  width: 30%;
}
.title-sistem-belajar {
  font-size: 22px;
}
.parent-desk-online {
  text-align: left;
}
.desk-sistem-belajar {
  font-size: 18px;
}
.desk-sistem-belajar-selengkapnya {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #054599;
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
}
.btn-regist-now-sistem-belajar {
  background-color: #f33a6b;
  padding: 10px;
  border-radius: 3px;
}

/* RESPONSIVE */
@media only screen and (min-width: 320px) and (max-width: 896px) {
    .flip-container {
        display: block;
    }
    .flip-box {
        margin-top: 10px;
    }
    .desk-sistem-belajar {
        font-size: 15px;
    }
    .desk-sistem-belajar-selengkapnya {
        font-size: 13px;
        font-weight: 400;
    }
    .title-sistem-belajar {
        font-size: 17px;
    }
    .super-title-sistem-belajar {
        font-size: 20px;
    }
}

/* RESPONSIVE */
/* @media only screen and (min-width: 320px) and (max-width: 1024px) {
    .icon-sistem-belajar {
        width: 20%;
    }
} */