.container-halaman-kota {
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 5%;
}
.content-kota {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #054599;
  padding: 20px;
  border-radius: 10px;
}
.isi-content-kota {
  display: flex;
  justify-content: center;
  align-items: center;
}
.model-halaman-kota {
  width: 27%;
}
.model-program-kota {
  display: none;
  width: 80%;
}
.title-halaman-kota {
  font-size: 30px;
  font-weight: 800;
}
.child-paragraf-kota {
  margin-top: 20px;
}
.teks-content {
  display: grid;
  gap: 20px;
}
.rumah-adat {
  width: 100%;
}

/* RESPONSIVE */
@media only screen and (max-width: 500px) {
  .container-halaman-kota {
    padding-left: 5%;
    padding-right: 5%;
  }
  .content-kota {
    display: grid;
    padding-bottom: 0;
    margin-top: 100px;
  }
  .rumah-adat {
    /* margin-bottom: -50px; */
  }
  .teks-content {
    margin-top: 65px;
  }
  .model-halaman-kota {
    position: fixed;
    z-index: 9999;
    right: 25px;
    bottom: 50px;
    width: 30%;
    filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.212));
  }
  .model-program-kota {
    display: flex;
    position: fixed;
    z-index: 9991;
    right: 10px;
    bottom: 28%;
    width: 30% !important;
    filter: drop-shadow(0.35rem 0.35rem 0.4rem rgba(0, 0, 0, 0.212));
    display: none;
  }
  .title-halaman-kota {
    font-size: 25px;
  }
  .paragraf-kota {
    font-size: 14px;
  }
}
@media only screen and (max-width: 768px) {
  .container-halaman-kota {
    padding-left: 3%;
    padding-right: 3%;
  }
  .teks-content {
    margin-top: 50px;
    text-align: justify;
  }
  .content-kota {
    gap: 25px;
  }
}
@media only screen and (max-width: 820px) {
  .container-halaman-kota {
    padding-left: 3%;
    padding-right: 3%;
  }
  .teks-content {
    margin-top: 50px;
    text-align: justify;
  }
  .content-kota {
    gap: 25px;
  }
}
@media only screen and (max-width: 1024px) {
  .container-halaman-kota {
    padding-left: 3%;
    padding-right: 3%;
  }
  .teks-content {
    margin-top: 50px;
    text-align: justify;
  }
  .content-kota {
    gap: 25px;
  }
}
