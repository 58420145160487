.parent-promo-cpns {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
}
.child-promo-cpns {
  width: 100%;
}
