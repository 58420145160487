/* PRODUK PTN */
.container-produk {
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 70px;
}
.card-produk {
  background-color: white;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  width: 24.2%;
}
.header-produk {
  width: 100%;
  border-radius: 5px 5px 0 0;
}
.parent-ratting-produk {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}
.star-ratting-produk {
  width: 35%;
}
.icon-keranjang-produk {
  margin-right: 8px;
}
.btn-pesan-produk-sekarang {
  /* display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #f57c0b;
  text-align: center;
  padding: 12px;
  margin-top: 10px;
  gap: 10px;
  font-weight: 500;
  border-radius: 5px; */

  position: relative;
  display: inline-block;
  padding: 12px;
  color: #fff;
  background-color: #f57c0b;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  overflow: hidden;
  outline: none;
  transition: background-color 0.3s ease;
  overflow: hidden;
  margin-top: 10px;
  width: 100%;
  text-align: center;
  font-weight: 500;
}
.btn-pesan-produk-sekarang::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 50%);
  transform: skewX(-20deg);
  transition: transform 0.5s ease;
  animation: shine 1.5s infinite;
}
@keyframes shine {
  0% {
      transform: translateX(-100%) skewX(-50deg);
  }
  50% {
      transform: translateX(100%) skewX(-50deg);
  }
  100% {
      transform: translateX(100%) skewX(-50deg);
  }
}

.fill-produk {
  padding: 15px;
}
.super-parent-produk {
  background-image: url("/src/assets/mt_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
}
.super-parent-produk-tni {
  background-image: url("/src/assets/mt_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 20px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  margin-top: 50px;
}
.super-title-produk {
  color: white;
  font-weight: 800;
  font-size: 28px;
}
.parent-card-ptn {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  gap: 10px;
  gap: 10px;
  margin-top: 20px;
}
.harga-produk {
  margin-top: 20px;
  color: #f57c0b;
  font-size: 19px;
  font-weight: 600;
}
.title-produk {
  font-size: 20px;
  font-weight: 700;
}
.child-ratting {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-rp {
  font-size: 10px;
}
.text-produk-terjual {
  font-weight: 600;
  font-size: 15px;
}

/* TO */
.parent-card-to {
  display: flex;
  justify-content: left;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

/* RESPONSIVE */
@media only screen and (min-width: 320px) and (max-width: 896px) {
  .container-produk {
    padding-left: 2%;
    padding-right: 2%;
  }
  .super-parent-produk,
  .super-parent-produk-tni {
    padding: 2px;
    padding-bottom: 5px;
  }
  .parent-card-ptn,
  .parent-card-to {
    justify-content: center;
  }
  .card-produk {
    width: 48%;
  }
  .super-title-produk {
    font-size: 20px;
    margin-top: 10px;
  }
  .title-produk {
    font-size: 14px;
    font-weight: 600;
  }
  .harga-produk {
    font-size: 15px;
  }
  .text-produk-terjual {
    font-size: 12px;
  }
  .star-ratting-produk {
    width: 50%;
  }
  .btn-pesan-produk-sekarang {
    font-size: 12px;
    gap: 5px;
    padding: 8px;
    border-radius: 2px;
  }
  .fill-produk {
    padding: 7px;
  }
}
