/* ASAL SEKOLAH SISWA */
.container-alumni {
  background-image: url("/src/assets/mt_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 30px;
  color: white;
  padding-top: 2%;
  /* padding-left: 15%;
  padding-right: 15%; */
  padding-bottom: 2%;
  margin-top: 50px;
  border-radius: 10px;
}
.title-alumni {
  text-align: center;
  /* font-weight: 600; */
  font-size: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.box-alumni {
  display: flex;
  gap: 10px;
  background: #ffffff;
  padding: 10px;
  height: 100vh;
  overflow: scroll;
  overflow-x: hidden;
  border: 10px solid #00418600;
  border-radius: 10px;
}
.list-alumni-siswa {
  line-height: 25px;
  list-style: none;
}
#style-2::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  /* border-radius: 10px; */
  background-color: #004186bd;
}

#style-2::-webkit-scrollbar {
  width: 12px;
  background: #004186bd;
}

#style-2::-webkit-scrollbar-thumb {
  /* border-radius: 10px; */
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: white;
}
.img-alumni {
  width: 100%;
}


/* RESPONSIVE */
@media only screen and (max-width: 500px) {
    .title-alumni {
        font-size: 20px;
    }
    .container-alumni {
        padding: 10px;
    }
  }