.parent-box-info-camp {
  background-image: url("/src/assets/mt_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px;
  margin-top: 50px;
  border-radius: 10px;
}
.box-infocamp {
  /* background-image: url("/src/assets/mt_bg.png");
  background-repeat: no-repeat;
  background-size: cover; */
  background-color: white;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  padding: 20px;
  border-radius: 10px;
}
.title-infocamp {
  text-align: center;
  color: rgb(0, 0, 0);
  font-size: 25px;
}
.child-infocamp {
  display: flex;
  justify-content: center;
  align-items: start;
}
.img-jadwal-camp {
  width: 50%;
}
.main-title-info-camp {
  text-align: center;
  color: white;
  font-size: 27px;
  margin-bottom: 20px;
}
.title-jadwal-kegiatan-camp {
  text-align: center;
  font-size: 28px;
}

/* LOKASI */
.child-box-info-camp-lokasi {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  gap: 20px;
}
.box-lokasi-program {
  display: flex;
  justify-content: center;
  align-items: start;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  padding: 20px;
  gap: 15px;
}
.img-lokasi {
  width: 15%;
}
.list-lokasi-camp {
  line-height: 25px;
  font-size: 17px;
  font-weight: 500;
  list-style: none;
  display: flex;
  align-items: baseline;
  gap: 8px;

}
.title-lokasi-camp {
  background-color: #1256af;
  border: 1px solid transparent;
  font-size: 20px;
  color: white;
  padding: 5px;
  border-radius: 2px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 10px;
}
.icon-info-camp {
  background-color:rgb(231, 159, 2);
  padding: 1px;
  border-radius: 100px;
  color: white;
}

/* WAKTU */
.child-box-info-camp-waktu {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  gap: 20px;
}
.box-waktu-program {
  display: flex;
  justify-content: center;
  align-items: start;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 10px;
  padding: 20px;
  gap: 15px;
}
.img-waktu {
  width: 15%;
}
.list-waktu-camp {
  line-height: 25px;
  font-size: 17px;
  font-weight: 500;
  list-style: none;
  display: flex;
  align-items: baseline;
  gap: 8px;
}
.title-waktu-camp {
  background-color: #1256af;
  border: 1px solid transparent;
  font-size: 20px;
  color: white;
  padding: 5px;
  border-radius: 2px;
  font-family: "Montserrat", sans-serif;
  margin-bottom: 10px;
}
.parent-garansi-camp-web {
  margin-top: 20px;
}
.img-garansi-camp-web {
  width: 100%;
}
.parent-garansi-camp-mob {
  display: none;
  margin-top: 20px;
}
.img-garansi-camp-mob {
  width: 100%;
}

/* RESPONSIVE */
@media only screen and (min-width: 320px) and (max-width: 896px) {
  .child-infocamp {
    display: grid;
  }
  .box-infocamp {
    padding: 10px;
    margin-top: 20px;
  }
  .child-box-info-camp-lokasi {
    display: grid;
  }
  .parent-box-info-camp {
    padding-left: 2%;
    padding-right: 2%;
  }
  .box-lokasi-program,
  .box-waktu-program {
    display: grid;
  }
  .img-lokasi,
  .img-waktu {
    width: 25%;
  }
  .img-jadwal-camp {
    width: 100%;
  }
  .parent-garansi-camp-mob {
    display: flex;
  }
  .parent-garansi-camp-web {
    display: none;
  }
  .main-title-info-camp {
    font-size: 20px;
  }
  .subtitle-info-camp {
    font-size: 15px;
  }
  .list-lokasi-camp, .list-waktu-camp {
    font-size: 14px;
    font-weight: 400;
  }
}
