.parent-promo-lpdp {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 50px;
}
.child-promo-lpdp {
  width: 100%;
}

.parent-promo-lpdp-mobile {
  /* display: flex;
      justify-content: center;
      align-items: center; */
  display: none;
  padding-top: 50px;
  padding-bottom: 50px;
}
.child-promo-lpdp-mobile {
  width: 100%;
}

/* RESPONSIVE */
@media only screen and (max-width: 500px) {
  .parent-promo-lpdp {
    display: none;
  }
  .parent-promo-lpdp-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
