/* *,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
} */

body {
  font-family: "Montserrat", sans-serif;
}

.navbar input[type="checkbox"],
.navbar .hamburger-lines {
  display: none;
}
.navbar-w-promo input[type="checkbox"],
.navbar-w-promo .hamburger-lines {
  display: none;
}

.container {
  /* max-width: 1200px;
  width: 100%;
  margin: auto; */
  padding-left: 15%;
  padding-right: 15%;
}

.navbar {
  /* background-image: url("/src/assets/border_atas.jpg");
  background-repeat: no-repeat;
  background-size: cover; */
  position: fixed;
  width: 100%;
  background: #054599;
  color: #ffffff;
  z-index: 9999;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;
  /* margin-top: 31px; */
  top: 0;
  padding-top: 20px;
  padding-bottom: 20px;
}
.navbar-w-promo {
  /* background-image: url("/src/assets/border_atas.jpg");
  background-repeat: no-repeat;
  background-size: cover; */
  position: fixed;
  width: 100%;
  background: #054599;
  color: #ffffff;
  z-index: 9999;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 0px;
  margin-top: 31px;
}
.icon-navbar {
  display: none;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: auto;
  align-items: center;
}

.menu-nav {
  display: flex;
  align-items: center;
  gap: 5px;
  color: white;
  font-weight: 500 !important;
  font-size: 15px;
  cursor: pointer;
  /* transform: scale(1.1); */
  padding-bottom: 13px;
  padding-top: 13px;
}

.menu-nav:hover {
  /* background-color: #00909b; */
  /* border-top: 2px solid #ffffff; */
  /* padding: 5px; */
  /* padding-left: 10px;
  padding-right: 10px; */
  /* font-size: 14px; */
  /* transform: scale(1.1); */
}
.parent-btn-daftar {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
.menu-nav-daftar {
  background-color: #00909b;
  border-radius: 2px;
  padding: 10px;
  padding-left: 30px;
  padding-right: 30px;
  transition: 0.5s;
  font-size: 14px;
}
.menu-nav-daftar:hover {
  background-color: #00818a;
  transition: 0.5s;
}
.menu-items {
  order: 2;
  display: flex;
}
.logo {
  /* order: 1;
  font-size: 2.3rem; */
}

.menu-items li {
  list-style: none;
  margin-left: 1.5rem;
  /* font-size: 1.3rem; */
  color: white;
}

.navbar a {
  text-decoration: none;
  font-weight: 500;
  /* transition: color 0.3s ease-in-out; */
}
.navbar-w-promo a {
  text-decoration: none;
  font-weight: 500;
  /* transition: color 0.3s ease-in-out; */
}

.logo-edumatrix {
  width: 11%;
  cursor: pointer;
}

/* DROPDOWN MENU */
.icon-navbar-1 {
  font-size: 12px;
}
.services {
  position: relative;
}
.dropdown {
  background-color: rgb(255, 255, 255);
  padding: 0;
  position: fixed;
  display: none;
  border-radius: 4px;
  /* top: 50px; */
  /* padding: 10px; */
  margin-top: 2px;
  left: 3%;
  width: 94%;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.dropdown li + li {
  /* margin-top: 10px; */
}
.dropdown li {
  /* padding: 0.5em 1em; */
  width: 20em;
  text-align: left;
}
/* .dropdown li:hover {
  background-color: rgba(240, 240, 240, 0.767);
  border-radius: 10px 0 0 10px;
} */
.services:hover .dropdown {
  display: block;
  transition: 0.1s;
}
.child-dropdown {
  display: flex;
  align-items: center;
  color: rgb(61, 61, 61);
  /* margin-top: 23px; */
  font-size: 15px;
  gap: 10px;
  /* margin-bottom: 23px; */
  padding: 7px;
  font-weight: 500;
}
.child-dropdown:hover {
  background-color: #f52f63;
  color: white;
  border-radius: 100px;
}
.icon-dropdown {
  color: rgb(0, 96, 126);
  padding: 5px;
}
.fill-dropdown {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
}
.top-loader {
  color: yellow;
}

@media (max-width: 768px) {
  .navbar {
    padding: 0;
  }
  .navbar-w-promo {
    padding: 0;
  }

  .navbar-container input[type="checkbox"],
  .navbar-container .hamburger-lines {
    display: block;
  }

  .navbar-container {
    display: block;
    position: relative;
    height: 64px;
  }

  .navbar-container input[type="checkbox"] {
    position: absolute;
    display: block;
    height: 32px;
    width: 30px;
    top: 20px;
    left: 20px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
  }

  .navbar-container .hamburger-lines {
    display: block;
    height: 23px;
    width: 27px;
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .navbar-container .hamburger-lines .line {
    display: block;
    height: 3px;
    width: 100%;
    background: #ffffff;
  }

  .navbar-container .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.3s ease-in-out;
  }

  .navbar-container .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
  }

  .navbar-container .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.3s ease-in-out;
  }
  .menu-nav {
    font-size: 13px;
  }

  .navbar .menu-items {
    padding-top: 30px;
    background: #054599;
    height: 100vh;
    max-width: 500px;
    transform: translate(-150%);
    display: flex;
    flex-direction: column;
    margin-left: -132px;
    padding-left: 66px;
    transition: transform 0.5s ease-in-out;
    overflow: scroll;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .navbar .menu-items li {
    margin-bottom: 1.8rem;
    font-size: 1.1rem;
    font-weight: 500;
  }
  .navbar-w-promo .menu-items {
    padding-top: 30px;
    background: #054599;
    height: 100vh;
    max-width: 500px;
    transform: translate(-150%);
    display: flex;
    flex-direction: column;
    margin-left: -132px;
    padding-left: 66px;
    transition: transform 0.5s ease-in-out;
    overflow: scroll;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }

  .navbar-w-promo .menu-items li {
    margin-bottom: 1.8rem;
    font-size: 1.1rem;
    font-weight: 500;
  }

  .logo-edumatrix {
    /* width: 35% !important;
    margin-left: 80%; */
  }

  .navbar-container input[type="checkbox"]:checked ~ .menu-items {
    transform: translateX(0);
  }

  .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line1 {
    transform: rotate(45deg);
  }

  .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line2 {
    transform: scaleY(0);
  }

  .navbar-container input[type="checkbox"]:checked ~ .hamburger-lines .line3 {
    transform: rotate(-45deg);
  }

  .logo-edumatrix {
    width: 33%;
    margin-left: 80%;
  }
}

@media (max-width: 500px) {
  .navbar-container input[type="checkbox"]:checked ~ .logo {
    display: none;
  }
  .icon-navbar {
    display: contents;
  }
  .logo-edumatrix {
    width: 64%;
    margin-left: 50%;
  }
  .dropdown {
    position: absolute;
  }
  .child-dropdown {
    font-size: 13px;
  }
  .dropdown li {
    width: 14em;
  }
  .fill-dropdown {
    align-items: center;
    padding-bottom: 70px;
    gap: 0;
  }
}
@media only screen and (max-width: 820px) {
  .navbar {
    padding-top: 5px;
    padding-bottom: 5px;
  }
  .navbar-w-promo {
    padding-top: 15px;
    padding-bottom: 15px;
  }
  .logo {
    width: 25%;
  }
}
@media only screen and (max-width: 1024px) {
  .logo {
    width: 25%;
  }
}

/* RESPONSIVE */
@media only screen and (max-width: 500px) {
  /* .navbar {
    display: none;
  } */
}
