.tentang-supercamp {
  /* background-image: url("/src/assets/wave_program_tni.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white; */
  border-radius: 10px;
  padding: 10px;
  text-align: center;
  margin-top: 30px;
  color: white;
}
.title-section-supercamp {
  background-color: rgb(5, 73, 150);
  color: white;
  padding: 10px;
  border: 1px solid transparent;
  border-radius: 3px;
  font-family: "Montserrat", sans-serif;
}
.paragraf-section-supercamp {
  margin-top: 20px;
  font-weight: 500;
  line-height: 25px;
}
.tentang-supercamp-2 {
  display: flex;
  justify-content: center;
  gap: 20px;
}
.box-mengapa-karantina {
  background-image: url("/src/assets/icon_supercamp//bg_spc.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: white;
  border-radius: 10px;
  padding: 16px;
  margin-top: 20px;
  width: 100%;
}
.list-section-supercamp {
  display: flex;
  align-items: baseline;
  list-style: none;
  gap: 5px;
  line-height: 30px;
  font-weight: 500;
}
.icon-section-supercamp {
  line-height: 30px;
  color: #ffffff;
  background-color: rgb(231, 159, 2);
  padding: 1px;
  border-radius: 100px;
}
.title-supercamp-2 {
  text-align: center;
}
.parent-section-supercamp {
  margin-top: 15px;
}
.img-mengapa-karantina {
  width: 50%;
  margin-left: 20px;
  margin-top: 15px;
}

/* RESPONSIVE */
@media only screen and (min-width: 320px) and (max-width: 896px) {
  .tentang-supercamp-2 {
    display: grid;
  }
  .img-mengapa-karantina {
    width: 100%;
    margin-left: 0;
  }
  .paragraf-section-supercamp {
    text-align: left;
    font-weight: 400;
    font-size: 14px;
  }
  .list-section-supercamp {
    font-weight: 400;
    font-size: 14px;
  }
}