.text-faq {
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  margin-bottom: 5px;
  color: white;
}

.faq-accordion {
  background-image: url("/src/assets/mt_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 30px;
  margin-top: 30px;
  padding: 20px;
  border-radius: 10px;
}

.icon-faq {
  color: white;
}

.question {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 10px 10px 0 0;
  max-width: 100%;
  border-bottom: 2px solid white;
  cursor: pointer;
}

.question p {
  flex: 1;
}

.garis-faq {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.child-garis-faq {
  width: 30%;
}

.answer {
  background-color: #ffffff;
  padding: 1rem;
  border-radius: 0 0 10px 10px;
  max-width: 100%;
  display: none;
  margin-top: 3px;
  line-height: 22px;
}

.answer.open {
  display: block;
}

.active {
  border-bottom: none;
}
.parent-all-accordion {
  display: grid;
  /* border: 1px solid rgb(221, 221, 221); */
  border-radius: 10px;
}
.line-faq {
  /* border: 1px dashed rgb(221, 221, 221); */
  margin-top: -10px;
}
.faq-pertanyaan {
  font-weight: 600;
}
.faq-jawaban {
  font-weight: 500;
}

/* RESPONSIVE */
@media screen and (max-width: 500px) {
  .faq-accordion {
    margin-bottom: 20px;
  }
  .text-faq {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 18px;
  }
  .faq-pertanyaan {
    font-size: 13px;
  }
  .faq-jawaban {
    font-size: 14px;
    font-weight: 400;
  }
}

@media screen and (max-width: 767px) {
  .faq-accordion {
    width: 100%;
    margin-top: 10px;
  }
  .accordion h1 {
    font-size: 22px;
  }
  .child-garis-faq {
    width: 50%;
  }
}
@media only screen and (max-width: 768px) {
  .faq-accordion {
    width: 100%;
    margin-bottom: 80px;
  }
  .question {
    max-width: 100%;
  }
  .answer {
    max-width: 100%;
  }
}
@media only screen and (max-width: 820px) {
  .faq-accordion {
    width: 100%;
    margin-bottom: 80px;
  }
  .question {
    max-width: 100%;
  }
  .answer {
    max-width: 100%;
  }
}
@media only screen and (max-width: 1024px) {
  .faq-accordion {
    width: 100%;
    margin-bottom: 50px;
    margin-top: 40px;
  }
  .question {
    max-width: 100%;
  }
  .answer {
    max-width: 100%;
  }
}
