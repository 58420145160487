.super-parent-testimoni-cpns {
    text-align: center;
    color: white;
    margin-top: 50px;
    background-image: url("/src/assets/mt_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    padding: 20px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  }
  .parent-testimoni-cpns {
    display: flex;
    justify-content: center;
    align-items: start;
    margin-top: 20px;
    gap: 10px;
  }
  .img-testimoni-cpns {
    width: 100%;
    border: 5px solid white;
    border-radius: 10px;
  }
  
  /* RESPONSIVE */
  @media only screen and (max-width: 500px) {
    .parent-testimoni-cpns {
      display: grid;
    }
    .img-testimoni-cpns {
      width: 100%;
      border: 2px solid white;
    }
  }
  
  @media only screen and (max-width: 768px) {
  }
  @media only screen and (max-width: 820px) {
    .parent-testimoni-cpns {
      display: grid;
    }
  }
  